<template>
  <section class="dtc-main-section mt-2 mr-2">
    <ViewStudentInfo></ViewStudentInfo>
    <section class="search-block">
      <h5 class="big-title">
        <span class="mr-3">編輯暑期課程學分費補助申請明細表</span>
        {{ editItem.BYear }}年度 培育學校: {{ editItem.SchoolName }}
      </h5>

      <div class="dtc-search1">
        <!-- <b-button variant="light" @click="openStudentInfo" >檢視公費生資訊</b-button> -->
        <b-button variant="warning" @click="openModal(null)" :disabled='!isEditable()'
          ><i class="fas fa-plus"></i>新增</b-button
        >
        <b-button variant="primary" @click="saveAllData" :disabled='!isEditable()'>儲存</b-button>
        <b-button variant="success" @click="goBackLastPage">返回</b-button>
        <b-button variant="danger" @click="openPrintPage">列印</b-button>
      </div>
    </section>
    <header class="dtc-grid-header ml-1 my-dark">
      <div>序</div>
      <div>功能</div>
      <div @click="sort()">
        姓名
        <span v-show="this.nameSortable.sortDesc === null" v-if="this.nameSortable.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="this.nameSortable.sortDesc === false" v-if="this.nameSortable.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="this.nameSortable.sortDesc" v-if="this.nameSortable.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
      <div>籍屬</div>
      <div>系別</div>
      <div>修習科目</div>
      <div>學分</div>
      <div>成績</div>
      <div class="header-column2">
        <div class="header-column2-row1">
          <div class="header-column2-row1-word">暑期班費用</div>
        </div>
        <div class="header-column2-row2">
          <div>每學分費用</div>
          <div>小計</div>
        </div>
      </div>
      <div>實際支付費用</div>
      <div>申請補助金額</div>
      <div>備註</div>
    </header>

    <table class="ml-1" v-for="(item, i) in items" :key="i" >
      <tr>
        <td rowspan="0" style="width:40px">{{ +i + 1 }}</td>
        <td style="width:160px" class="table-content">
          <b-button
            variant="success"
            class="mr-2"
            size="sm"
            @click="openModal(item,i)"
            :disabled='!isEditable()'
            >編輯</b-button
          >
          <b-button 
          variant="danger" 
          size="sm"
          @click="delFee(i)"
          :disabled='!isEditable()'
          >刪除</b-button>
        </td>
        <td rowspan="0" style="width:120px" >{{ item.modalName }}</td>
        <td rowspan="0" style="width:110px">{{ modalCountryName(item.modalCountry)}}</td>
        <td rowspan="0" style="width:170px">{{ modalMajorName(item.modalMajorId) }}</td>
        <td style="width:220px">{{ item.modalSubject }}</td>
        <td style="width:120px">{{ item.modalCredit }}</td>
        <td style="width:120px">{{ item.modalScore }}</td>
        <td style="width:110px" class="price-content">{{ $formatPrice(item.modalCreditFee) }}</td>
        <td style="width:110px" class="price-content">{{ $formatPrice(item.modalCredit * item.modalCreditFee) }}</td>
        <td style="width:130px" class="price-content">{{ $formatPrice(item.modalRealFee) }}</td>

        <td rowspan="0" style="width:130px" class="price-content">{{ $formatPrice(item.modalRealFee) }}</td>
        <td style="width:200px">{{ item.modalComment }}</td>
      </tr>
    </table>

    <footer
      class="dtc-grid-footer main-dtc-body ml-1"
      style="color: #212529; background-color: #fff;border-top:0px"
    >
      <div class="column1-content">總計申請</div>
      <div class="price-content">{{ $formatPrice(totalFee) }}</div>
      <div></div>
    </footer>
      <b-modal
        id="editModal"
        :title="`${editItemElement.modalType == 1 ? '新增' : '編輯'}暑期課程學分費補助`"
        size="md"
        header-bg-variant="warning"
      >
        <div class="equip-grid-2">
          <b-input-group prepend="身分證字號"><b-input :value="editItemElement.modalIdentifier" @change="updateName" class="edit-content"></b-input></b-input-group>
          <b-input-group prepend="姓名" class="mt-2"><div class="edit-content form-control">{{ editItemElement.modalName || '輸入身份證字號帶出...' }}</div></b-input-group>
          <b-input-group prepend="籍屬" class="mt-2"><b-select :options="countryItems" v-model="editItemElement.modalCountry"></b-select></b-input-group>
          <b-input-group prepend="系別" class="mt-2"><b-select :options="majorItems" v-model="editItemElement.modalMajorId" ></b-select></b-input-group>
          <b-input-group prepend="修習科目" class="mt-2">
            <b-input v-model="editItemElement.modalSubject" class="edit-content"></b-input>
          </b-input-group>
          <b-input-group prepend="學分" class="mt-2">
            <vue-numeric
              :precision="1"
              v-model="editItemElement.modalCredit"
              style="width: 341px;text-align: end;padding-right:5px"
              :max="99"
              class="edit-content"
            ></vue-numeric>
          </b-input-group>
          <b-input-group prepend="每學分費用" class="mt-2">
            <vue-numeric
              separator=","
              v-model="editItemElement.modalCreditFee"
              style="width: 341px;text-align: end;padding-right:5px"
              :max="9999"
              class="edit-content"
            ></vue-numeric>
          </b-input-group>
          <b-input-group prepend="成績" class="mt-2">
            <b-input
              v-model="editItemElement.modalScore"
              style="width: 341px;text-align: end;padding-right:5px"
              class="edit-content"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="實際支付費用" class="mt-2">
            <vue-numeric
              separator=","
              v-model="editItemElement.modalRealFee"
              style="width: 341px;text-align: end;padding-right:5px"
              :max="999999"
              class="edit-content"
            ></vue-numeric>
          </b-input-group>
          <b-input-group prepend="備註" class="mt-2">
            <b-input
              v-model="editItemElement.modalComment"
              placeholder="輸入備註"
              class="edit-content"
            ></b-input>
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <span class="text-danger">註：請務必確認各欄位資料、費用及成績(需及格方可申請補助)</span>
          </div>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editModal')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="saveFee"
              >儲存</b-button
            >
          </div>
        </template>
      </b-modal>
  </section>
</template>

<script>
import { store } from "@/store/global.js";
const year = new Date().getFullYear() - 1905;
let years = new Array(8).fill().map((s, i) => year + 1 - i);
import ViewStudentInfo from "./ViewStudentInfo";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

export default {
  name: "ShowTable",
  data() {
    return {
      indexTable: 0,
      tableTitle: "",
      nowObject: {},
      items: [],
      headers: [],
      nameSortable: { name: "姓名", key: "modalName", sortDesc: null },
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      years,
      selectedYear: new Date().getFullYear() - 1911,
      idx:null,
      countryItems: [], 
      majorItems: [], 
      editItem: {}, //表頭，shead edit
      editItemElement: { //申請細項，summerclass edit
        modalType: 1,
        modalBno: 0,
        modalName: "",
        modalCountry: "",
        modalMajorId: "",
        modalSubjetotalFeect: "",
        modalCredit: "",
        modalCreditFee: 0,
        modalScore: 0,
        modalRealFee: 0,
        modalComment: "",
      },

      totalFee: 0,
    };
  },
  components: { ViewStudentInfo },
  props: [],
  
  computed: {
    nowTableTitle() {
      return store.nowTableTitle;
    },
    nowTab() {
      return store.nowTab;
    },
  },
  filters:{
    
  },
  methods: {
    isEditable(){
      return this.editItem.Status == 0 || this.editItem.Status == 5;
    },
    renewModal(db) {
      if(db == null){
        return { 
          modalType: 1,
          modalBno: 0,
          modalName: "",
          modalIdentifier: "",
          modalCountry: "",
          modalMajorId: "",
          modalMajorName: "",
          modalSubject: "",
          modalCredit: 0,
          modalCreditFee: 0,
          modalScore: 0,
          modalRealFee: 0,
          modalComment: "",
        };
      } else {
        return { 
          modalType: 2,
          modalBno: db.Bno,
          modalName: db.Name,
          modalIdentifier: db.Identifier,
          modalCountry: db.Country,          
          modalMajorId: db.MajorId,
          modalMajorName: db.MajorName,
          modalSubject: db.StudyClass,
          modalCredit: db.Hours,
          modalCreditFee: db.Tuition,
          modalScore: db.Fraction,
          modalRealFee: db.RealPay,
          modalComment: db.Remark,
        };
      }
    },
    modalCountryName(v){ 
      if(this.countryItems.length > 0 && v){
        let rr =  this.countryItems.find(r => r.value == v);
        return rr?rr.text : v;
      }
      return '';
    },
    modalMajorName(v){ 
      if(this.majorItems.length > 0 && v){
        let rr = this.majorItems.find(r => r.value == v);
        return rr?rr.text:v; 
      }
      return '';
    },
    openModal(item,idx) {
      if(item == null){
        this.editItemElement = this.renewModal();
      }else{
        this.idx = idx;
        this.editItemElement = Object.assign({},item);
      }
      this.$bvModal.show("editModal");
    },
    openPrintPage() {
      let url = "";
      url = `/#/docsummer?seqno=${this.editItem.SeqNo}&schoolcode=${this.editItem.SchoolCode}&byear=${this.editItem.BYear}&term=1`;
      window.open(url);
    },
    
    saveData(number, key, i, major) {
      const remind = () => {
        this.$bvToast.toast("輸入值請勿超過上限", {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      };

      if (number > 5000 && key == 12) {
        this.items[i].col12 = 5000;
        remind();
      } else if (number > 18000 && key == 13) {
        this.items[i].col13 = 18000;
        remind();
      } else if (
        number > 30000 &&
        key == 14 &&
        (major == "醫學系" || major == "牙醫系")
      ) {
        this.items[i].col14 = 30000;
        remind();
      } else if (number > 15000 && key == 14) {
        this.items[i].col14 = 15000;
        remind();
      }
    },
    searchDb() {
      this.search = true;
      this.getData();
    },
    async getCountry(){
      try {
        let { Items, Count } = await window.axios.get(`api/Country`);
        if(Items){
          Items=Items.filter((r)=>{ return r.IsActive });
          Items = Items.map((s) => ({
            value: s.Cid,
            text: s.CountryName,
          }));
        }
        this.countryItems = Items;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getStudentInfo(identifier){
      try { 
        let res = await window.axios.get(`StudentInfo/GetStudent?identifier=${identifier}`);
        return res
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "danger",
          });
      }
    },
    async updateName(e) {
      this.editItemElement.modalIdentifier = e;     
      try {
        const res = await this.getStudentInfo(e);
        this.editItemElement.modalName = res?res.Name:'';
        if(!res){
          this.$bvToast.toast(`身分證查無學員資料`, {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "danger",
          });
        }
      } catch(error){
        console.log(error.response);
      } 
    },
    
    async getMajor(){
      try {
        let Items = await window.axios.get(`Major/Get?schoolcode=${this.editItem.SchoolCode}`);
        if(Items){
          Items = Items.map((s) => ({
            value: s.MajorId,
            text: s.MajorName,
          }));
        }
        //console.log('getMajor',this.editItem.SchoolCode,Items);
        this.majorItems = Items;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    updateTotalFee(){
      this.totalFee = 0;
      this.items.forEach((s) => {
        this.totalFee = this.totalFee + s.modalRealFee;
      });
    },
    sort() {
      var ctrlItem = this.nameSortable;
      if (!ctrlItem.key) {
        return;
      }
      if (ctrlItem.sortDesc) {
        ctrlItem.sortDesc = false;
      } else if (false === ctrlItem.sortDesc) {
        ctrlItem.sortDesc = true;
      } else if (null === ctrlItem.sortDesc) {
        ctrlItem.sortDesc = true;
      }

      if(ctrlItem.sortDesc){
        this.items.sort(function(a, b){
          if(a.modalName < b.modalName) { return -1; }
          if(a.modalName > b.modalName) { return 1; }
          return 0;
        })
      }else{
        this.items.sort(function(a, b){
          if(a.modalName < b.modalName) { return 1; }
          if(a.modalName > b.modalName) { return -1; }
          return 0;
        })
      }
    },
    async getData() {
      try {
        let res = await window.axios.get(`Budgeting/GetSummer2?seqno=${this.editItem.SeqNo}`);
        if(res != null){
          this.nowObject = res;
          const { budgeting_summer_class } = this.nowObject;
          const total = budgeting_summer_class.reduce(
            (acc, cur) => acc + +cur.RealPay,
            0
          );
          this.totalFee = total;
          this.items=[];
          //MajorName
          var sc = [...budgeting_summer_class];
          
          sc.forEach((s) => {
            this.items.push(this.renewModal(s));
          });    
        }
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    openStudentInfo() {
      this.$bvModal.show("viewStudentInfo");
    },
    saveFee() {
      var data = { ...this.editItemElement };
      var msg = [];
      if(!data.modalName) { msg.push(`學生姓名未填寫`);}
      if(msg.length > 0){
        this.$bvToast.toast(msg.join('、'), {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "danger",
          });
        return;
      }
      if(this.editItemElement.modalType == 1){
        //新檔
        data.modalType = 2;
        this.items.push(data);
        this.updateTotalFee();
      }else{
        this.items.splice(this.idx, 1,data);  
        this.updateTotalFee();
      }
      this.$bvModal.hide('editModal')
    },
    delFee(idx) {
      this.items.splice(idx, 1);
      this.updateTotalFee();
    },
    async goBackLastPage() {
      this.$router.push("/focusbudgetmanagetab");
    },
    async saveAllData() {
      let arr = [...this.items];
      arr = arr.map((s) => ({
        Sno: this.editItem.SeqNo,
        Bno: s.modalBno, //s.Bno,
        Identifier: s.modalIdentifier,
        Country: s.modalCountry,
        MajorId: s.modalMajorId,
        StudyClass: s.modalSubject,
        Hours: s.modalCredit,
        Fraction: s.modalScore,
        Tuition: s.modalCreditFee,
        RealPay: s.modalRealFee,
        Remark: s.modalComment,
      }));
      const obj = {
        SeqNo: this.editItem.SeqNo,
        Details: [...arr],
      };

      const url = `Budgeting/SetSummerDetails`;
      try {
        let res = await window.axios.put(url, obj);
        if(res){
          this.$bvToast.toast(`資料儲存成功`, {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "success",
          });
        }else{
          this.$bvToast.toast(`儲存失敗`, {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "danger",
          });
        }
        this.getData();
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  mounted() {
    this.editItem = { ...store.editItem };
    this.getCountry();
    this.getMajor();
    this.getData();
  },
  watch: {
    
  },
};
</script>

<style lang="scss" scoped>
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header {
  display: grid;
  grid-template-columns: 40px 160px 120px 110px 170px 220px 120px 120px 220px 130px 130px 200px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;

  border: 1px solid #dee2e5;
  border-bottom: 0px;
  margin-right: 4px;
  color: #0757a7;
  > div {
    border-right: 1px solid #dee2e5;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    height: 87px;
    padding-top: 38px;
  }
  > div:last-child {
    border-right: none;
  }
  .header-column2 {
    padding-top: 0px;

    .header-column2-row1 {
      text-align: center;
      height: 55px;
      line-height: 55px;
    }
    .header-column2-row2 {
      display: grid;
      grid-template-columns: 110px 110px;
      > div {
        border-right: 1px solid #dee2e5;
        border-top: 1px solid #dee2e5;
        height: 32px;
        line-height: 32px;
      }
    }
  }
}
.main-dtc-body {
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  > div {
    line-height: 24px;
  }
}
.dtc-grid-main,
.dtc-grid-footer {
  display: grid;
  grid-template-columns: 40px 120px 50px 120px repeat(11, 120px) 176px;
  margin-right: 4px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;
  border-left: 1px solid #dee2e5;
  border-right: 1px solid #dee2e5;

  color: #212529;
  > div {
    border-right: 1px solid #dee2e5;
    color: #212529;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  > div:last-child {
    border-right: none;
  }
}
.dtc-grid-footer {
  grid-template-columns: 1410px 130px 200px;
  border: 1px solid #dee2e5;
  color: #212529;

  > div {
    border-right: 1px solid #dee2e5;
    margin-top: 0px;
  }
  > div:last-child {
    border-right: none;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #333;
    color: white;
  }
}

::v-deep.btn-light {
  background: #7e2ca3;
  border-color: #7e2ca3;
  color: #fff;
}
.search-block {
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 2px 2px 0 0;
  width: 99.3%;
  margin: 0 auto 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 2px 2px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
    display: grid;
    max-height: 40px;
    margin-bottom: 12px;
    grid-template-columns: repeat(30, max-content);
    grid-gap: 12px;
    > * {
      max-height: 44px;
    }
  }
}
td {
  border: 1px solid #dee2e5;
  text-align: center;
}
.table-content {
  padding: 3px;
}
.input-group-text {
  width: 122px;
  background: #0379fd;
  color: #fff;
}
.edit-content {
  text-align: end;
}
.price-content {
  text-align: right;
  padding-right: 6.5px;
}
</style>
